import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import isEmpty from "lodash/isEmpty";
import {
  BULLET_TYPES,
  CONTROLLER_TYPES,
  LANGUAGE,
  LOADER_TYPES,
  commonLoaderStyle
} from "@/constants";

import { isMobile, isServer } from "@/util";
import { selectCdnImageSettings } from "@/util/selectors";
import { getCatalogCarouselData } from "@/redux/actions/common.action";
import { productCarouselSwiperConfig } from "./swiperConfigs";
import useOnScrollHandling from "@/hooks/intersection-observer";
import { getAutoScrollConfig } from "@/util/carousel";
import useSwiperScript from "@/hooks/useSwiperScript";
import PlpAnalytics from "@/services/analytics/main/plp";
import CatalogService from "@/services/catalogService";
import { Product } from "@/services/productService";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useCountryId, useLongLanguage, useRouteSlug } from "@/hooks";
import { SwiperProductSize } from "../swiperProductSize";
import ContentImage from "@/components/content/contentImage";
import BannerIntersectionObserver from "@/components/banner/bannerIntersectionObserver";
import Loader from "@/components/loader";
import { SalePersentageBlock } from "@/components/dynamic/dynamic-sale/SalePersentageBlock";
import PriceSlab from "@/components/price/priceSlab";
import { BannerRow } from "@/routes/catalogPage/types";
import { Column } from "@/components/dynamic/dynamicColumns/types";

type FlashSaleImageList = {
  imageList: Product[];
};

type ContentRow = BannerRow | Column;

const truncateProductTitle = (title, characters) => {
  if (title && title.length > characters)
    return title.substring(0, characters) + "...";
  else {
    return title;
  }
};

const getElement = (el, className) =>
  !isServer && el && el.querySelector(className);

const CatalogProductCarousel: FC<{
  getImageList: (data: Product | FlashSaleImageList) => any;
  content: ContentRow;
  bannerModuleName: string;
  bannerPageTitle: string;
  isSaleCarousel: boolean;
}> = ({
  getImageList,
  content,
  bannerModuleName,
  bannerPageTitle,
  isSaleCarousel
}) => {
  const { t } = useTranslation("productListing");

  const catalogCarouselRef = useRef(null);
  const swiperElRef = useRef(null);
  const slug = useRouteSlug();
  const language = useLongLanguage();
  const countryId = useCountryId();
  const configCdnImagesSettings = useAppSelector(selectCdnImageSettings);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isIntersecting } = useOnScrollHandling(catalogCarouselRef);
  const { isIntersecting: isIntersectingWithProduct } = useOnScrollHandling(
    catalogCarouselRef,
    {
      threshold: 1,
      constantTracking: true
    }
  );
  const [imageList, setImageList] = useState<Product[]>([]);
  const [isCarouselFetched, setIsCarouselFetched] = useState(false);
  const loadedScriptStatus = useSwiperScript();

  const data = content[language];
  const indicatorStyles = content.indicatorStyles;
  const activeBullet = catalogCarouselRef?.current?.querySelector(
    `.bullet-active${content.id}`
  );

  const isPagination = content.indicatorType !== BULLET_TYPES.NONE;
  const isNavigationEnabled =
    content.controllerType &&
    content.controllerType !== CONTROLLER_TYPES.NONE &&
    !isMobile.any();

  const swiperNavPrevId = `swiper-button-prev-${content.id}`;
  const swiperNavNextId = `swiper-button-next-${content.id}`;

  useEffect(() => {
    if (swiperElRef?.current && (loadedScriptStatus || window.Swiper)) {
      const paginationForSwiper = {
        pagination: {
          el: ".swiper-pagination",
          renderBullet: (index, className) => {
            return `<span class='${className} ${content.indicatorType} product-carousel-bullet bullet${content.id}'></span>`;
          },
          bulletActiveClass: `swiper-pagination-bullet-active bullet-active${content.id}`,
          clickable: true
        },
        on: {
          slideChange: onSlideChange
        }
      };

      const navigation = {
        nextEl: `#${swiperNavNextId}`,
        prevEl: `#${swiperNavPrevId}`
      };
      const autoScrollConfig = getAutoScrollConfig(content?.autoScroll);

      const isArabic = language === LANGUAGE.arabic;

      const slidesOffsetAfter = isMobile.any() ? 170 : 300;

      const params = {
        loop: !isEmpty(autoScrollConfig),
        ...autoScrollConfig,
        ...productCarouselSwiperConfig,
        ...(!isMobile.any() && { navigation }),
        ...(isPagination && paginationForSwiper)
      };

      const swiper = new window.Swiper(swiperElRef.current, {
        ...params
      });

      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [content.id, imageList.length, language, loadedScriptStatus]);

  useEffect(() => {
    if (isIntersecting) {
      isSaleCarousel ? getSaleCarouselData() : getPageData(data.searchUrl);
    }
  }, [isIntersecting]);

  useEffect(() => {
    onSlideChange();
  }, [activeBullet, imageList.length]);

  useEffect(() => {
    let timeout = null;

    const handleImpressions = () => {
      if (
        !isEmpty(imageList) &&
        isIntersectingWithProduct &&
        catalogCarouselRef
      ) {
        timeout = setTimeout(() => {
          const refCurrent = catalogCarouselRef.current;
          const parent = refCurrent?.querySelector(".swiper-wrapper");
          if (!isEmpty(parent)) {
            // exact size of viewport
            const { width: containerWidth } = parent.getBoundingClientRect();
            // exact size of first product in a row , to calculate.
            const { width: elementWidth } =
              parent.children?.[0]?.getBoundingClientRect();
            const visibleProductsCount = Math.round(
              containerWidth / elementWidth
            );
            const visibleProducts = imageList.slice(0, visibleProductsCount);
            PlpAnalytics.impressions({
              items: visibleProducts,
              listHeading: data.title
            });
          }
        }, 1000);
      } else {
        clearTimeout(timeout);
      }
    };

    if (isIntersectingWithProduct) {
      timeout = setTimeout(handleImpressions, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [imageList, isIntersectingWithProduct]);

  const setControllerStyles = () => {
    const carouselWrapper = !isServer && catalogCarouselRef?.current;

    const nextButton = getElement(carouselWrapper, `#${swiperNavNextId}`);
    const prevButton = getElement(carouselWrapper, `#${swiperNavPrevId}`);

    if (nextButton && prevButton && content.controllerStyles) {
      nextButton.style.background =
        content.controllerStyles.backgroundControllerColor;
      prevButton.style.background =
        content.controllerStyles.backgroundControllerColor;
    }
  };
  const setActiveBulletColor = () => {
    const activeBullet = catalogCarouselRef?.current?.querySelector(
      `.bullet-active${content.id}`
    );
    if (activeBullet) {
      activeBullet.style.background = indicatorStyles?.indicatorColor;
    }
  };

  const onSlideChange = () => {
    const allBullets = catalogCarouselRef?.current?.querySelectorAll(
      `.bullet${content.id}`
    );
    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background = indicatorStyles?.backgroundIndicatorColor;
      });
      setActiveBulletColor();
    }
  };

  const getPageData = async searchUrl => {
    const response = await dispatch(
      getCatalogCarouselData({
        url: searchUrl,
        countryId,
        language,
        storeId: 2
      })
    );
    const imageList = getImageList(response[language]);

    setImageList(imageList);

    if (content.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  };

  const getSaleCarouselData = async () => {
    const url = content[language].flashSalePageSlug;

    const response = await CatalogService.getCarouselData({
      url,
      countryId,
      language,
      isFlashSale: true
    });

    const carouselData = {
      enable: true,
      searchUrl: slug,
      type: "product-carousel",
      [language]: { imageList: response.hits }
    };

    const imageList = getImageList(
      carouselData[language] as FlashSaleImageList
    );

    setImageList(imageList);
    setIsCarouselFetched(true);

    if (content.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  };

  const handleProductSelect = (product: Product, idx: number) => {
    PlpAnalytics.select({
      item: { ...product, position: idx },
      listHeading: product.title.defaultValue
    });
  };

  const rowStyles = {
    ...content.styles,
    maxWidth: content.width?.value || "100%",
    marginLeft: "auto",
    marginRight: "auto"
  };

  const viewAllLink = isSaleCarousel ? data.redirectionUrl : data.searchUrl;

  if (isCarouselFetched && !Boolean(imageList.length)) {
    return null;
  }

  return (
    <div
      className={cn(
        "catalog_product_carousel_section dynamic_product_carousel",
        {
          arabic: language === LANGUAGE.arabic
        }
      )}
      id={content.id}
      ref={catalogCarouselRef}
    >
      {imageList.length ? (
        <>
          {data.title && (
            <div className="head_with_desc">
              <h2>{data.title}</h2>
              <button
                className="view-all"
                onClick={() => history.push(`/${slug}${viewAllLink}`)}
              >
                {t("viewAll")}
              </button>
            </div>
          )}
          <div className="catalogue-slider-wrap" style={rowStyles}>
            <div
              className="swiper carousel-slider"
              ref={swiperElRef}
              id={`swiper-${content.id}`}
            >
              <div className="swiper-wrapper">
                {imageList.map((product, index) => {
                  const isPriceAvailable =
                    product.isPriceAvailableInUserCountry;
                  const dataForAnalytic = {
                    bannerModuleName: bannerModuleName?.toUpperCase(),
                    bannerRowName: content.title?.toUpperCase(),
                    index: index + 1,
                    url: product.captionImageURL
                      ? `${configCdnImagesSettings?.list}${product.captionImageURL}`
                      : "",
                    pageName: bannerPageTitle
                  };
                  const redirectionLink = `/${slug}/${product.url}`;
                  const saleOutOfStock = !product?.pstock && isSaleCarousel;
                  const productOverlayTagLabel = product?.overlayTagLabel;

                  const productOverlayTagLabelStyles =
                    productOverlayTagLabel?.style;

                  return (
                    <div
                      className="catalogue_carousel_wrapper swiper-slide"
                      key={product.id}
                    >
                      <div className="catalogue_item">
                        <div className="catalogue_img_wrap">
                          <BannerIntersectionObserver data={dataForAnalytic}>
                            <Link
                              to={redirectionLink}
                              className="carousel-link"
                              onClick={() =>
                                handleProductSelect(product, index)
                              }
                              data-banner-link={redirectionLink}
                              data-banner-index={index}
                            >
                              <span
                                className={cn({
                                  "unavailable-product": saleOutOfStock
                                })}
                              >
                                <ContentImage
                                  img={product.captionImageURL}
                                  url={configCdnImagesSettings?.list}
                                  overlayLabel={productOverlayTagLabel?.label}
                                  overlayTagBgColor={
                                    productOverlayTagLabelStyles?.backgroundColor
                                  }
                                  overlayTagLabelColor={
                                    productOverlayTagLabelStyles?.textColor
                                  }
                                  item={product}
                                  showDiscountSlab={!isSaleCarousel}
                                />
                              </span>

                              {saleOutOfStock && (
                                <p className="out-of-stock">{t("soldOut")}</p>
                              )}
                              {isSaleCarousel && (
                                <SalePersentageBlock product={product} />
                              )}
                            </Link>
                          </BannerIntersectionObserver>
                        </div>
                        <div className="catalogue_content">
                          <div className="product-size-wrapper">
                            <SwiperProductSize
                              displaySizes={product.displaySizes}
                              isShortLabel
                            />
                          </div>

                          {product.brand && <h4>{product.brand?.label}</h4>}
                          <p>{truncateProductTitle(product.title.label, 50)}</p>

                          {isPriceAvailable && (
                            <PriceSlab
                              product={product}
                              retailPriceVisible
                              discountLabelVisible={false}
                              priceLabelVisible
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {isNavigationEnabled && (
                <>
                  <div
                    className="swiper-button-prev"
                    id={swiperNavPrevId}
                  ></div>
                  <div
                    className="swiper-button-next"
                    id={swiperNavNextId}
                  ></div>
                </>
              )}
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </>
      ) : (
        <Loader
          type={LOADER_TYPES.DOTS}
          size={16}
          qty={3}
          visible
          style={commonLoaderStyle}
        />
      )}
    </div>
  );
};

export default CatalogProductCarousel;
