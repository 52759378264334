import React, { FC, useContext, useRef, useState } from "react";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "@/redux/store";
import { Product } from "@/services/productService";
import ContentImage from "@/components/content/contentImage";
import { ItemDescription } from "../itemDescription";
import ProductSizes from "../sizes";
import { SaleWishList } from "@/components/dynamic/dynamic-sale/SaleWishList";
import SyteDiscoveryButtonWrapper from "../syteDiscoveryButton";
import AppOnlyItemInfoModal from "../appOnlyItemInfoModal";

import { selectCdnImageSettings, selectSyteSettings } from "@/util/selectors";
import { isMobile, isTouchScreen, isTabletView } from "@/util";
import PlpAnalytics from "@/services/analytics/main/plp";
import { useRouteSlug } from "@/hooks";
import { AlgoliaInsightsService } from "@/services";
import { algoliaPLPContext } from "../productListingPage";
import "./style.scss";

const GRID_CLASS_NAMES = {
  4: "four-view",
  6: "six-view"
};

const ListItem: FC<{
  item: Product;
  position: number;
  listHeading?: string;
  toggleQuicklook: (
    toggleState: boolean,
    selectedIndex: number,
    event?: MouseEvent
  ) => void;
}> = ({ item, position, listHeading, toggleQuicklook }) => {
  const { t } = useTranslation("productListing");
  const syteButtonRef = useRef<HTMLDivElement>(null);
  const [toggleAppOnlyItemModal, setToggleAppOnlyItemModal] = useState(false);
  const gridValue = useAppSelector(
    state => state.productListingReducer.gridValue
  );
  const routeSlug = useRouteSlug();
  const configSyteSettings = useAppSelector(selectSyteSettings);
  const isDiscoveryButtonEnabled = configSyteSettings?.enableDiscoveryIcon;
  const configCdnImagesSettings = useAppSelector(selectCdnImageSettings);
  const { algoliaIndex, algoliaQueryId } = useContext(algoliaPLPContext);

  if (!item) return null;

  const onClick = event => {
    PlpAnalytics.select({
      item: { ...item, position },
      listHeading
    });

    const isSyteEvent = syteButtonRef.current.contains(event.target);

    if (!isSyteEvent && item.isAppOnlyPurchase) {
      event.preventDefault();
      setToggleAppOnlyItemModal(true);
      return;
    }
    AlgoliaInsightsService.trackProductClick({
      productId: item.id,
      position: position + 1,
      queryID: algoliaQueryId,
      algoliaIndex
    });
  };

  const onQuicklookClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (item.isAppOnlyPurchase) {
      setToggleAppOnlyItemModal(true);
      return;
    }
    PlpAnalytics.select({
      item: { ...item, position },
      listHeading
    });

    toggleQuicklook(true, position, event);
  };

  const isMobileTabletView = Boolean(isMobile.any()) || isTabletView();
  const mobileTabletViewClassName = cn({
    "mobile-tablet-view": isMobileTabletView
  });

  const isTouch = isTouchScreen();
  const isMobileSyteSearchButton =
    isDiscoveryButtonEnabled && (isTouch || isMobileTabletView);

  return (
    <li className={cn("listing_grid", "list", GRID_CLASS_NAMES[gridValue])}>
      <Link
        onClick={onClick}
        to={{
          pathname: `/${routeSlug}/${item.url}`,
          state: { fromPLP: true },
          search: algoliaQueryId ? `?queryId=${algoliaQueryId}` : ""
        }}
        className="hover_view_content"
      >
        <div
          className={cn("position-relative", {
            hover_layer: !isMobileTabletView
          })}
        >
          <div
            id="hover_view_product_image"
            className={cn("hover_view", {
              isMobileDevice: isMobileTabletView
            })}
          >
            <div className={`listing_image_wrap ${item.id}`}>
              <div className="listing_hover_content">
                {item.isPriceAvailableInUserCountry && (
                  <SaleWishList product={item} />
                )}
                {isMobileSyteSearchButton && (
                  <SyteDiscoveryButtonWrapper
                    className={cn("listing-mobile", mobileTabletViewClassName)}
                    item={item}
                    isMobile
                  />
                )}
                <span className="quickview_btn" onClick={onQuicklookClick}>
                  {t("quickLook")}
                </span>
              </div>
              <ContentImage
                img={item.captionImageURL}
                url={configCdnImagesSettings?.list}
                overlayLabel={item.overlayTagLabel?.label}
                overlayTagBgColor={item.overlayTagLabel?.style?.backgroundColor}
                overlayTagLabelColor={item.overlayTagLabel?.style?.textColor}
                showDiscountSlab
                item={item}
                showDefaultImage
              />
            </div>
          </div>
          <div id="hover_view_product_size" className="hover_view">
            <div className="hover_view_content" ref={syteButtonRef}>
              {isDiscoveryButtonEnabled && !isTouch && (
                <SyteDiscoveryButtonWrapper
                  className="listing position-relative"
                  item={item}
                  text={t("viewSimilar")}
                />
              )}
            </div>
          </div>
        </div>
        <ProductSizes sizes={item.displaySizes} gridSize={gridValue} />
        <ItemDescription item={item} />
      </Link>
      {item.isAppOnlyPurchase && (
        <AppOnlyItemInfoModal
          toggleModal={toggleAppOnlyItemModal}
          setToggleModal={setToggleAppOnlyItemModal}
          product={item}
        />
      )}
    </li>
  );
};

export default ListItem;
