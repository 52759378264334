import isEmpty from "lodash/isEmpty";
import { BEConfig } from "../../config/env";

import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi
} from "../../webapis/apiResource";

import { CustomerService, LoyaltyPointsService } from "../../services";

import {
  MY_ACCOUNT_DETAIL,
  SET_MY_ORDERS,
  SET_USER_LOYALTY_POINTS,
  SET_LOYALTY_TABLE,
  SET_CANCEL_ORDER,
  SET_WALLET,
  UPDATE_WALLET,
  RESET_CANCEL_ORDER,
  ADD_TO_WALLET,
  TRACK_ORDER,
  CLEAR_TRACK_ORDER,
  FETCH_SOCIAL_ACCOUNT,
  TOGGLE_ADD_NEW_CARD_TAB,
  HANDLE_APP_DOWNLOAD_DETAILS,
  RESET_MY_ACCOUNT_DETAIL,
  SET_USER_WALLET_EXPIRY_DATA,
  SET_LOYALTY_EXPIRY_DATA,
  SET_LOYALTY_EARNED_POINTS,
  RESET_LOYALTY_PROGRAM_INFO,
  SET_UPDATE_ERROR,
  SET_PHONE_UPDATE_FLOW_DATA,
  SHOW_MY_ORDERS_LOADER,
  HIDE_MY_ORDERS_LOADER,
  SET_IS_LOADING_LOYALTY_POINTS,
  SET_LOYALTY_POINTS_TIER,
  SET_WALLET_AMOUNT
} from "../constants";
import { TOAST_TYPES } from "@/constants";

import {
  hideLoadingIndicator,
  setIsCheckedLoyaltyProgram,
  setLoyaltyProgramStatus,
  showLoadingIndicator,
  getConfigurationSettings
} from "./common.action";
import { toastMsg } from "@/util/toast";
import { ApiError } from "./homepage.action";
import { fetchUserAndLoadData } from "./authConfig.action";

import { getVisitorId, languageFromPathName } from "../../util";
import { getAccessToken } from "../../util/storeHelper";
import { handleFetchError } from "../../util/errorHandler";
import { getDataAsObject } from "../../util/common";
import AccountAnalyticsEvent from "../../services/analytics/main/myAccount";

export const handleAppDownloadInfo = (name, value, status) => {
  return { type: HANDLE_APP_DOWNLOAD_DETAILS, name, value, status };
};
export const myAccountDetails = info => ({ type: MY_ACCOUNT_DETAIL, info });
export const resetMyAccountDetails = () => ({ type: RESET_MY_ACCOUNT_DETAIL });

const setMyOrders = (apiResponse, pageNum) => ({
  type: SET_MY_ORDERS,
  apiResponse: {
    ...apiResponse,
    order: apiResponse.map(data => ({
      ...data,
      pageNum
    }))
  },
  pageNum
});

const setEarnedLoyaltyPoints = apiResponse => ({
  type: SET_USER_LOYALTY_POINTS,
  apiResponse
});

const setLoyaltyTierToken = tierToken => ({
  type: SET_LOYALTY_POINTS_TIER,
  tierToken
});

const setLoyaltyTable = (apiResponse, pageNum) => ({
  type: SET_LOYALTY_TABLE,
  apiResponse,
  pageNum
});

const setLoyaltyExpiryData = expiryData => ({
  type: SET_LOYALTY_EXPIRY_DATA,
  payload: expiryData
});

const setLoyaltyEarnedPoints = earnedPointsData => ({
  type: SET_LOYALTY_EARNED_POINTS,
  payload: earnedPointsData
});

const setWallet = apiResponse => ({ type: SET_WALLET, apiResponse });
export const setCancelOrder = (name, value) => ({
  type: SET_CANCEL_ORDER,
  name,
  value
});

const updateWallet = apiResponse => ({ type: UPDATE_WALLET, apiResponse });

const setWalletExpiryData = apiResponse => ({
  type: SET_USER_WALLET_EXPIRY_DATA,
  payload: apiResponse
});

const setWalletAmount = (amount, currencyCode, countryId) => ({
  type: SET_WALLET_AMOUNT,
  data: [{ transactionAmount: amount, currencyCode, countryId }]
});

export const resetLoyaltyProgramInfo = () => ({
  type: RESET_LOYALTY_PROGRAM_INFO
});

export const resetCancelOrder = () => ({ type: RESET_CANCEL_ORDER });
export const addToWallet = (name, value) => ({
  type: ADD_TO_WALLET,
  name,
  value
});
export const trackOrderResponse = apiResponse => ({
  type: TRACK_ORDER,
  apiResponse
});
export const socialAccount = apiResponse => ({
  type: FETCH_SOCIAL_ACCOUNT,
  apiResponse
});
export const toggleAddNewCardTab = value => ({
  type: TOGGLE_ADD_NEW_CARD_TAB,
  value
});
export const clearTrackOrder = () => ({ type: CLEAR_TRACK_ORDER });

export const setUpdateError = error => ({
  type: SET_UPDATE_ERROR,
  payload: error
});
export const resetUpdateError = () => dispatch => dispatch(setUpdateError(""));

export const setPhoneUpdateFlowData = data => ({
  type: SET_PHONE_UPDATE_FLOW_DATA,
  payload: data
});

export const resetPhoneUpdateFlowData = () => ({
  type: SET_PHONE_UPDATE_FLOW_DATA
});

export const showMyOrdersLoader = () => ({
  type: SHOW_MY_ORDERS_LOADER
});

export const hideMyOrdersLoader = () => ({
  type: HIDE_MY_ORDERS_LOADER
});
export const setIsLoadingLoyaltyPoints = value => ({
  type: SET_IS_LOADING_LOYALTY_POINTS,
  value
});

export const fetchMyOrders =
  (pageNum = 1, months = 6, pageSize = 5, showLoader = true) =>
  async dispatch => {
    if (showLoader) dispatch(showMyOrdersLoader());
    dispatch(getConfigurationSettings());

    try {
      const ordersData = await CustomerService.getOrderList({
        pageSize,
        pageNum,
        months
      });

      dispatch(setMyOrders(ordersData, pageNum));

      return ordersData;
    } catch (error) {
      handleFetchError(error, dispatch);
      dispatch(setMyOrders([], pageNum));

      return error.response;
    } finally {
      if (showLoader) dispatch(hideMyOrdersLoader());
    }
  };

export const fetchLoyaltyDetails =
  (language, showLoader = true) =>
  async dispatch => {
    if (showLoader) {
      dispatch(showLoadingIndicator());
      dispatch(setIsLoadingLoyaltyPoints(true));
    }

    language = languageFromPathName(language);

    try {
      dispatch(setIsCheckedLoyaltyProgram(false));
      const loyaltyDetailsData = await LoyaltyPointsService.getLoyaltyDetails(
        language
      );
      dispatch(
        setLoyaltyProgramStatus(Boolean(loyaltyDetailsData.loyaltyDetails))
      );
      dispatch(setEarnedLoyaltyPoints(loyaltyDetailsData));
      dispatch(setIsCheckedLoyaltyProgram(true));

      if (!Boolean(loyaltyDetailsData.loyaltyDetails)) {
        dispatch(setWallet({ walletAmount: [], walletHistoryDetails: [] }));
      }
      return loyaltyDetailsData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      if (showLoader) {
        dispatch(hideLoadingIndicator());
        dispatch(setIsLoadingLoyaltyPoints(false));
      }
    }
  };

export const fetchLoyaltyTierToken = () => async (dispatch, getState) => {
  const { language } = getState().common;
  try {
    const loyaltyDetailsData = await LoyaltyPointsService.getLoyaltyDetails(
      language
    );
    dispatch(setLoyaltyTierToken(loyaltyDetailsData.tierToken));

    return loyaltyDetailsData;
  } catch (error) {
    console.error("Call fetchLoyaltyTierToken faced the error\n", error);
    return error.response;
  }
};

export const fetchLoyaltyPointsTable =
  (language, pageNum = 1, pageSize = 4, countryId) =>
  async dispatch => {
    dispatch(showLoadingIndicator());
    language = languageFromPathName(language);

    try {
      const data = await LoyaltyPointsService.getLoyaltyPointsTable(
        language,
        pageNum,
        pageSize,
        countryId
      );

      dispatch(setLoyaltyTable(data, pageNum));

      return data;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const fetchWalletExpiryDetails =
  (language, currency, countryId) => async dispatch => {
    if (!(currency && countryId)) return null;

    dispatch(showLoadingIndicator());
    language = languageFromPathName(language);
    try {
      const walletExpiryDetails =
        await LoyaltyPointsService.getWalletExpiryDetails(
          countryId,
          currency,
          language
        );

      dispatch(setWalletExpiryData(walletExpiryDetails));
      return walletExpiryDetails;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const updateUser = bodyData => {
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.userHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(fetchUserAndLoadData());
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          dispatch(setUpdateError(error.response?.data));
        } else {
          handleFetchError(error, dispatch);
        }
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const cancelOrder = (bodyData, activePage) => {
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.cancelOrder}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          AccountAnalyticsEvent.cancelOrderSuccess({
            orderId: bodyData.orderId
          });
          dispatch(fetchMyOrders(activePage));
          toastMsg(TOAST_TYPES.SUCCESS, response.data.message, true);
        } else {
          toastMsg(TOAST_TYPES.ERROR, response.data.message, true);
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchMyWallet =
  (language, currencyCode, pageNum = 1) =>
  async (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    language = languageFromPathName(language);
    const { countryId } = getState().common.settings;

    try {
      const responseArray = await LoyaltyPointsService.getMyWalletDetails(
        currencyCode,
        countryId,
        language,
        pageNum
      );

      let walletData = {};
      responseArray.forEach(response => {
        const { transactionAmount } = response.data;
        if (transactionAmount) {
          walletData = {
            ...walletData,
            walletAmount: [{ transactionAmount, currencyCode, countryId }]
          };
        } else {
          walletData = { ...walletData, ...response.data };
        }
      });

      if (!isEmpty(walletData)) {
        dispatch(setWallet(walletData));
      }

      return responseArray;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.error(
          "500 error on user wallet fetching with message:",
          error.response.data.message
        );
        return error.response;
      }
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const fetchMyWalletHistory =
  (language, currencyCode, pageNum = 1) =>
  async (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    language = languageFromPathName(language);
    const { countryId } = getState().common.settings;

    try {
      const { data } = await LoyaltyPointsService.getWalletHistory(
        currencyCode,
        countryId,
        language,
        pageNum
      );

      let walletData = {};

      const { transactionAmount } = data;

      if (transactionAmount) {
        walletData = {
          ...walletData,
          walletAmount: [{ transactionAmount, currencyCode, countryId }]
        };
      } else {
        walletData = { ...walletData, ...data };
      }

      if (!isEmpty(walletData)) {
        dispatch(updateWallet(walletData));
      }

      return data;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const fetchMyWalletAmount =
  (language, currencyCode, pageNum = 1) =>
  async (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    language = languageFromPathName(language);
    const { countryId } = getState().common.settings;

    try {
      const { data } = await LoyaltyPointsService.getWalletBalance(
        currencyCode,
        countryId,
        language,
        pageNum
      );

      if (data.success) {
        dispatch(
          setWalletAmount(data.transactionAmount, currencyCode, countryId)
        );
      }

      return data;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const trackOrder = (bodyData, language) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.trackOrder}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(trackOrderResponse(response.data));
        } else {
          if (response.data.success === false) {
            dispatch(trackOrderResponse(response.data));
          }
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const redeemLoyaltyPoints = bodyData => async dispatch => {
  dispatch(showLoadingIndicator());

  try {
    const response = await LoyaltyPointsService.redeemLoyaltyPoints(bodyData);

    dispatch(fetchLoyaltyDetails(bodyData.language));
    dispatch(
      fetchLoyaltyPointsTable(
        bodyData.language,
        1,
        bodyData.pageSize,
        bodyData.countryId
      )
    );

    return response;
  } catch (error) {
    toastMsg("error", error.message);
    dispatch(ApiError(error.response));
    handleFetchError(error, dispatch);
    return error.response;
  } finally {
    dispatch(hideLoadingIndicator());
  }
};

export const getLoyaltyExpiryDetails =
  (countryId, currencyCode, language) => async dispatch => {
    dispatch(showLoadingIndicator());

    try {
      const expiryDetails = await LoyaltyPointsService.getLoyaltyExpiryDetails(
        countryId,
        currencyCode,
        language
      );

      dispatch(setLoyaltyExpiryData(expiryDetails));

      return expiryDetails;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const getLoyaltyEarnedPoints =
  (countryId, currencyCode, language) => async dispatch => {
    dispatch(showLoadingIndicator());

    try {
      const loyaltyEarnedPoints =
        await LoyaltyPointsService.getLoyaltyEarnedPoints(
          countryId,
          currencyCode,
          language
        );

      dispatch(setLoyaltyEarnedPoints(loyaltyEarnedPoints));

      return loyaltyEarnedPoints;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const registerLoyaltyProgram =
  (bodyData, language, currencyCode) => async dispatch => {
    dispatch(showLoadingIndicator());
    try {
      const data = await LoyaltyPointsService.registerLoyaltyProgram(bodyData);
      dispatch(fetchLoyaltyDetails(language));

      return data;
    } catch (error) {
      dispatch(ApiError(error.response || getDataAsObject(error.message)));
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const fetchSocialAccount = language => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.socialAccount}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(socialAccount(response.data));
        } else {
          if (response.data.success === false) {
            dispatch(socialAccount(response.data));
          }
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deleteSocialAccount = (socialLoginId, language) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.socialAccount}/${socialLoginId}`;
  return (dispatch, getState) => {
    return deleteWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data.success) {
          dispatch(fetchSocialAccount(language));
        } else {
          toastMsg("error", "Something went wrong");
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const verifyReceivedOtp = ({ token, otp }) => {
  const { protocol, baseURL, port, versionInfo, completeUserDataUpdate } =
    BEConfig.customerApi;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${completeUserDataUpdate}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, { token, otp })
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
        } else {
          dispatch(setPhoneUpdateFlowData(response.data));
        }
        return response;
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          dispatch(setUpdateError(error.response?.data));
        } else {
          handleFetchError(error, dispatch);
        }
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const sendOtpToVerifyNumber = ({ token }) => {
  const { protocol, baseURL, port, versionInfo, sendUpdatingOtp } =
    BEConfig.customerApi;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${sendUpdatingOtp}`;
  return (dispatch, getState) => {
    return postDashboardWebApi(getAccessToken(getState), apiURL, { token })
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(setPhoneUpdateFlowData(response.data));
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          dispatch(setUpdateError(error.response?.data));
        } else {
          handleFetchError(error, dispatch);
        }
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const getLinkedAccounts = ({ token, otp }) => {
  const { protocol, baseURL, port, versionInfo, getDeactivateDataForUpdate } =
    BEConfig.customerApi;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${getDeactivateDataForUpdate}`;
  return (dispatch, getState) => {
    return postDashboardWebApi(getAccessToken(getState), apiURL, { token, otp })
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(setPhoneUpdateFlowData(response.data));
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deactivatePrevAccountsAndCompleteUpdate =
  ({ token }) =>
  dispatch =>
    dispatch(verifyReceivedOtp({ token }));
