export const HOMEPAGE_SECTION_TYPES = {
  BANNER: "banner",
  IMAGE_CAROUSEL: "catalog-carousel",
  NEWEST_ARRIVAL: "newestArrival",
  PRODUCT_CAROUSEL: "ProductCarousel",
  SHOP_BY_BRANDS: "shopByBrands",
  SHOP_BY_STORE: "shopByStore",
  TOP_BANNER_CONTENT: "topBannerContent",
  TOP_CATEGORIES: "topCategories",
  SLIDER: "Slider",
  DYNAMIC_CAROUSEL: "Carousel",
  HTML: "HTML",
  DYNAMIC_BANNERS: "dynamicBannerModule",
  CUSTOM: "Custom",
  SINGLE: "Single",
  FLASH_SALE_CAROUSEL: "CurrentFSV2ProductCarousel",
  TAB: "tab",
  ACCORDION: "Accordion",
  COLUMN: "column",
  LIST: "list",
  TREE: "tree"
};

export const STORYLY_SDK_URL =
  process.env.REACT_APP_STORYLY_SDK_URL ||
  "https://web-story.storyly.io/v2/storyly-web.js";

export const TABBY_SNIPPET_URL = "https://checkout.tabby.ai/tabby-card.js";

export const STORYLY_EVENTS = {
  isReady: "isReady", // Triggered when story is ready.
  noStoryGroup: "noStoryGroup", // Triggered when there is no story group.
  openStoryGroup: "openStoryGroup", // Triggered when clicked a story group.
  closeStoryGroup: "closeStoryGroup", // Triggered when closed story group.
  actionClicked: "actionClicked", // Triggered when clicked Swipe Up or Action Button.
  productTagExpanded: "productTagExpanded", // Triggered when clicked Product Tag Icon.
  productTagClicked: "productTagClicked" // Triggered when clicked Product Tag Tooltip.
};

export const DYNAMIC_MODULE_NAMES = {
  STORYLY: "storyly",
  LISA: "lisa",
  LIVE_FLOATING_BUTTON: "liveFloatingButton",
  NEWS_LETTER: "newsLetter",
  BRAME_GAMIFICATION: "brameGamification",
  FLASH_SALE: "flashSale",
  DOWNLOAD_MOBILE_APP: "downloadMobileApp",
  FOOTER_COUNTRY_SELECTION: "countrySelection",
  FOOTER_HELPLINE: "helpline",
  FOOTER_CUSTOMER_CARE: "customerCare",
  FOOTER_WHATSAPP: "whatsapp",
  FOOTER_CONNECT_WITH_US: "connectWithUs"
};

export const LISA_CLIENT_NAME = process.env.REACT_APP_LISA_CLIENT_NAME || "bfl";
export const LISA_LIBRARY_HOST_NODE = "lisa-library";
export const LISA_LIBRARY_PLAYER_ID = "lisa-library-player";

export const LISA_LIBRARY_SDK_URL =
  "https://static.lisa-cdn.net/sdks/lisa-embed-library/latest/lisa-embed-library.latest.js";

export const LISA_MODAL_SDK_URL =
  "https://static.lisa-cdn.net/sdks/modal/latest/modal.latest.js";

export const LISA_HUB_SDK_URL =
  "https://static.lisa-cdn.net/sdks/lisa-web-sdk/latest/lisa-web-sdk.latest.js";

export const LISA_IFRAME_URL = `https://${LISA_CLIENT_NAME}.loveslisa.tech`;
export const LISA_RECIPIENT = "LiSA";
export const LISA_PLAYER_CLASS = "lisa-hub__player-frame";

export const LIVE_FLOATING_BUTTON_TYPES = {
  LISA: "LISA",
  BFL: "BFL"
};

export const SWIPER_V11_URL =
  "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
