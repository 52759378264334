import get from "lodash/get";
import { deleteWebApi, fetchWebApi } from "../../webapis/apiResource";
import {
  SESSION_TERMINATED,
  STORE_TOKEN,
  LOGOUT_SUCCESS,
  SAVE_USER_DETAILS,
  SET_ADDRESS_BOOK,
  SET_DEFAULT_ADDRESS,
  SHOW_PREVIEW,
  SET_PREV_PATH,
  STORE_TOKEN_CHECKOUT_GUEST,
  RESET_TOKEN_CHECKOUT_GUEST,
  SET_EMAIL
} from "../constants";
import { getAccessToken } from "../../util/storeHelper";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { emptyWishListAndCart } from "./wishlist.action";
import {
  setUserStorage,
  removeUserStorage,
  removeGuestUserStorage,
  setUserEmailStorage,
  removeUserEmailStorage
} from "../../util/browserStorage";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  setCountryListData,
  setLoyaltyProgramStatus
} from "./common.action";
import { ApiError, userProfile } from "./homepage.action";
import { clearCartAndWishlistGeneralData } from "./cart.action";
import { resetCardList } from "./checkout.action";
import { clearTrackOrder, resetLoyaltyProgramInfo } from "./myAccount.action";
import AnalyticService from "../../services/analytic-service";
import {
  filterAddressByState,
  getVisitorId,
  updateVisitorId
} from "../../util/index";
import { checkIsUserPhoneDeactivated } from "../../util/user";
import { StorageService, UserDetailsService } from "../../services";
import { toastMsg } from "@/util/toast";

export const setToken = ({ accessToken, token = accessToken }) => {
  return dispatch => {
    dispatch(tokenGenerator(token));
    removeGuestUserStorage(document.domain);
    setUserStorage(token, document.domain);
  };
};

export const setUserInfo = ({ userProfile }) => ({
  type: SAVE_USER_DETAILS,
  userProfile
});

export const setUserAddressBook = addressBook => ({
  type: SET_ADDRESS_BOOK,
  addressBook
});

export const setDefaultAddress = (name, value, index) => ({
  type: SET_DEFAULT_ADDRESS,
  name,
  value,
  index
});

export const setPrevPath = prevPath => ({ type: SET_PREV_PATH, prevPath });

export const fetchAddressBook = countryId => {
  const {
    customerApi: { baseURL, protocol, port, versionInfo, addressHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${addressHandle}?nationality=${countryId}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        const addressDetails = get(response, "data.addressDetails");
        if (
          response.status === 200 &&
          response.data.success &&
          addressDetails
        ) {
          const reduxStore = getState();
          const state = get(reduxStore, "common.settings.state");
          const filteredAddressBook = filterAddressByState(
            addressDetails,
            state
          );
          const isUserLoggedIn = get(reduxStore, "authReducer.userLoggedIn");
          isUserLoggedIn && dispatch(setUserAddressBook(filteredAddressBook));
        } else {
          if (response.data.success === false) {
            dispatch(setUserAddressBook());
          }
          if (response.status === 401) {
            dispatch(logOut());
          }
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
export const deleteAddress = addressId => {
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.addressHandle}/${addressId}`;
  return (dispatch, getState) => {
    const reducerState = getState();
    const countryId = reducerState.common.settings.countryId;
    return deleteWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data.success) {
          dispatch(fetchAddressBook(countryId));
        } else {
          toastMsg("error", response.data.message);
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
export const fetchUserAndLoadData = ({ keepDeliverySettings } = {}) => {
  const {
    customerApi: { baseURL, protocol, port, versionInfo, userHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${userHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    const reducerState = getState();
    const countryId = reducerState.common.settings.countryId;
    const token = getAccessToken(getState);
    return fetchWebApi(token, apiURL)
      .then(async response => {
        const userShouldBeLoggedOut =
          (token && !response.data.success) ||
          checkIsUserPhoneDeactivated(response.data);
        const dataFetchingAllowed =
          response.status === 200 &&
          response.data.success &&
          !userShouldBeLoggedOut;

        if (dataFetchingAllowed) {
          !keepDeliverySettings && dispatch(setCountryListData([]));
          await dispatch(setUserInfo(response.data));
          const email = response.data?.userProfile?.email;
          email && setUserEmailStorage(email);
          await dispatch(fetchAddressBook(countryId));
        } else if (response.status === 401 || userShouldBeLoggedOut) {
          dispatch(logOut());
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const logOut = doNotTrackPrevHistory => {
  AnalyticService.auth.trackLogout();
  removeUserStorage(document.domain);
  removeUserEmailStorage();
  StorageService.removeOrdersHistoryFrom();
  updateVisitorId();
  return dispatch => {
    dispatch(logOutUser());
    dispatch(emptyWishListAndCart());
    dispatch(clearCartAndWishlistGeneralData());
    dispatch(resetLoyaltyProgramInfo());
    dispatch(setLoyaltyProgramStatus(false));
    dispatch(resetCardList());
    !doNotTrackPrevHistory && dispatch(setPrevPath(window.location.pathname));
    dispatch(setCountryListData([]));
    dispatch(clearTrackOrder());
  };
};

export const tokenGenerator = token => ({ type: STORE_TOKEN, token });
export const tokenGeneratorCheckoutGuest = token => ({
  type: STORE_TOKEN_CHECKOUT_GUEST,
  token
});
export const resetTokenGeneratorCheckoutGuest = () => ({
  type: RESET_TOKEN_CHECKOUT_GUEST
});
export const showPrevMode = value => ({ type: SHOW_PREVIEW, value });

export const terminateSession = () => ({ type: SESSION_TERMINATED });

export const logOutUser = () => ({ type: LOGOUT_SUCCESS });

export const setEmail = email => ({ type: SET_EMAIL, payload: email });

export const updateAuthData = (email, password) => {
  return async dispatch => {
    try {
      const response = await UserDetailsService.getUserAuthData(
        email,
        password
      );
      if (response.status === 200 && response.data && response.data.success) {
        dispatch(userProfile(response.data));
        dispatch(setToken(response.data));
      } else {
        dispatch(ApiError(response.data));
      }
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.message;
    }
  };
};
