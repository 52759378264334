import { isTablet, isMobile as isMob, isMobileOnly } from "react-device-detect";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";
import split from "lodash/split";
import isString from "lodash/isString";
import some from "lodash/some";
import includes from "lodash/includes";
import size from "lodash/size";
import { ContextStorage } from "@/libs/contextStorage";

import {
  LANGUAGE_ROUTE_KEY,
  LANGUAGE_ROUTE_KEY_MAP,
  EXCLUDE_COUNTRY_SELECTION_DROPDOWN,
  BRAND_NAME_URL_SUFFIX,
  TAMARA,
  languageCountryRegExp,
  DEFAULT_VIEW_GRID_VALUE
} from "../constants";

import { selectRouteSlug, selectWishListGeneralProducts } from "./selectors";
import { DESKTOP, MOBILE, TABBY, TABLET } from "../constants";
import {
  PREFERRED_LANGUAGE,
  UNIQUE_ID,
  VIEW_GRID,
  VISITOR_ID
} from "../redux/constants";
import { getDefaultCookieOptions } from "./browserStorage";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const isIpadInSafari = () => {
  if (isServer) return false;
  if (navigator.userAgent.match(/iPad/i)) {
    return true;
  } else if (
    navigator.userAgent.match(/Mac/i) &&
    navigator.maxTouchPoints > 0 &&
    !isMob
  ) {
    return true;
  }
  return false;
};

export const getDeviceType = () => {
  if (isIpadInSafari() || isTablet) {
    return TABLET;
  } else if (isMobileOnly) {
    return MOBILE;
  } else {
    return DESKTOP;
  }
};

export const isMobile = {
  Android: !isServer ? () => navigator.userAgent.match(/Android/i) : () => {},
  BlackBerry: !isServer
    ? () => navigator.userAgent.match(/BlackBerry/i)
    : () => {},
  iOS: !isServer
    ? () =>
        (navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
          window.orientation === 90) ||
        (navigator.userAgent.match(/Macintosh/i) &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 1)
    : () => {},
  Opera: !isServer ? () => navigator.userAgent.match(/Opera Mini/i) : () => {},
  Windows: !isServer ? () => navigator.userAgent.match(/IEMobile/i) : () => {},
  Tablet: !isServer
    ? () => navigator.userAgent.match(/Tablet/i) && window.orientation === 90
    : () => {},
  WindowsTablet: !isServer
    ? () => navigator.userAgent.match(/Tablet PC/i) && window.orientation === 90
    : () => {},
  otherMobileDevice: !isServer
    ? () => navigator.userAgent.match(/Mobi/i)
    : () => {},
  any: !isServer
    ? () =>
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows() ||
        isMobile.Tablet() ||
        isMobile.WindowsTablet() ||
        isMobile.otherMobileDevice() ||
        isMob
    : () => {}
};

export const isMobileFooterView = () =>
  isMobile.any() &&
  isMobile.any().length &&
  window &&
  window.screen.width <= 834;

export const isTabletView = () => {
  const tabletViewTag = !isServer
    ? /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase()
      ) || isTablet
    : false;
  return tabletViewTag;
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const checkVideoURL = url => url.match(/\.(mp4)$/) !== null;

export const isArray = value =>
  value && typeof value === "object" && value.constructor === Array;

export const isProduction = () => {
  return process.env.REACT_APP_ENV === "production" ? true : false;
};

export const encodeSpace = name => {
  try {
    return name.replace(/ /g, "%20");
  } catch (error) {
    return name;
  }
};

export const getProductUrlName = (name = "") => {
  let urlName = name && name.replace(/\s+/g, "-").toLowerCase();
  urlName = urlName && urlName.replace(/&/g, "and");
  urlName = urlName && urlName.replace(/\\|\/|\|/g, "-");
  urlName = urlName && urlName.replace(/[`=*^%$#@!<>?[\]{}(),;~'".+]/g, "");
  urlName = urlName && urlName.replace(/(\b)?(\s+)(\b)?/g, "");

  return urlName;
};

export const getProductUrl = (product, withOriginLocation = true) => {
  const state = ContextStorage.store.getState();
  const routeSlug = selectRouteSlug(state);

  const urlName = getProductUrlName(product.title);
  const originLocation = withOriginLocation
    ? `${document.location.origin}`
    : "";

  const url = `${originLocation}/${routeSlug}/${urlName}/${product.id}/p/`;

  return url;
};

export const getBaseUrl = props => {
  const { language, commonSettings } = props;
  const countrySHORT = get(commonSettings, "countrySHORT", "ae").toLowerCase();

  return `/${language}-${countrySHORT}`;
};

export const getProductBrandUrl = product => {
  const brandSlug = product.brand?.key;
  if (!brandSlug) {
    throw new Error(`Missing required parameter 'brand.key'`);
  }

  return `${BRAND_NAME_URL_SUFFIX}/${brandSlug}/`;
};

export const handleScrollTop = directSmooth => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: directSmooth
  });
};

export const languageFromPathName = data => {
  let language = LANGUAGE_ROUTE_KEY.english;
  if (
    data &&
    (data.includes(`${LANGUAGE_ROUTE_KEY.arabic}-`) ||
      data.includes(`${LANGUAGE_ROUTE_KEY.arabic}`))
  ) {
    language = LANGUAGE_ROUTE_KEY.arabic;
  }
  return language;
};

export const countryFromPathName = data => {
  return data && data.split("-")[1].replace("/", "");
};

export const getUrlBasedLanguage = url =>
  languageFromPathName((url.match(languageCountryRegExp) || [])[0]);

export const getUrlBasedCountryCode = url =>
  countryFromPathName((url.match(languageCountryRegExp) || [])[0]);

export const selectedCountryName = (countryArray, countryId) => {
  const selectedCountry =
    countryArray &&
    countryId &&
    countryArray.filter(c => c.countryId === countryId);
  return selectedCountry && selectedCountry[0].countryName;
};

export const addAnimate = (
  item,
  isClicked,
  wishList,
  addClass,
  selectedSize,
  isDetailPage = false
) => {
  const isPresent = some(
    wishList,
    wishItem =>
      wishItem.productId === item.id &&
      (isDetailPage
        ? selectedSize === wishItem.size
        : selectedSize || "" === wishItem.size)
  );
  if (!isPresent && isClicked) {
    addClass = "addAnimation";
  } else if (isPresent && !isClicked) {
    addClass = "";
  } else if (isPresent && isClicked) {
    addClass = "removeAnimation";
  } else if (!isPresent && !isClicked) {
    addClass = "";
  }
  return addClass;
};

export const itemsInCart = cartItems => {
  if (!cartItems) return 0;
  return cartItems.reduce((total, item) => {
    if (item.isSelected) {
      return total + +get(item, "quantity", 0);
    }
    return total;
  }, 0);
};

class UserAgentService {
  #isDesktopView = true;

  isDesktop = () => {
    return !!this.#isDesktopView;
  };

  setView = (userAgent = "") => {
    this.#isDesktopView = !(
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i) ||
      userAgent.match(/Tablet/i) ||
      userAgent.match(/Tablet PC/i)
    );
  };
}

export const _countryStoreSelection = (
  store,
  url,
  userCountry,
  common,
  setCommonData
) => {
  let _country = null,
    countryAE = {},
    selectedCountry = null,
    selectedIPCountry = null,
    countryCookie = null,
    countryData = common.countryData || [];

  countryData.map(_country => {
    if (
      url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
      url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
    ) {
      selectedCountry = _country;
    }
    if (_country.countrySHORT === userCountry) {
      countryCookie = _country;
    }
    if (_country.countrySHORT === "AE") {
      countryAE = _country;
    }
    if (
      common.ipCountryData &&
      common.ipCountryData.countrySHORT &&
      common.ipCountryData.countrySHORT === _country.countrySHORT
    ) {
      selectedIPCountry = _country;
    }
    return null;
  });

  if (selectedCountry) {
    _country = selectedCountry;
    store.dispatch(setCommonData(selectedCountry));
  } else if (!selectedCountry) {
    if (countryCookie) {
      _country = countryCookie;
      store.dispatch(setCommonData(countryCookie));
    } else if (selectedIPCountry) {
      _country = selectedIPCountry;
      store.dispatch(setCommonData(selectedIPCountry));
    } else {
      _country = countryAE;
      store.dispatch(setCommonData(countryAE));
    }
  }

  const _storeList = (_country && _country.stores) || [];
  let storeId = null,
    _bflStoreId = null,
    _storeMap = getStoreMap({ stores: _storeList });

  _storeList &&
    _storeList.forEach(item => {
      if (url.includes(_storeMap[item.mid])) {
        storeId = item.mid;
      }
      if (item.name.toLocaleLowerCase().includes("brands")) {
        _bflStoreId = item.mid;
      }
    });

  return { _country, storeId, _bflStoreId };
};

export const UserAgentManager = new UserAgentService();

export const checkCountry = (key, currentCountryCode) => {
  if (key.indexOf(currentCountryCode) >= 0) {
    return true;
  }
  return false;
};

export const getBrand = (shopByStore, storeId, language) => {
  const storesArray = get(
    shopByStore,
    `${LANGUAGE_ROUTE_KEY_MAP[language]}["storeList"]`,
    []
  );
  const filteredBrand = storesArray.filter(store => storeId === +store.storeId);
  return filteredBrand[0] || {};
};

export const isArabic = language => language.includes("ar");

export const removeWhiteSpaces = text => {
  return text.split(" ").join("");
};

export const handleContinueShoppingButtonUrl = () => {
  let pathName = window.location.pathname;
  let splittedPathName = pathName.split("/");
  pathName = pathName.replace(splittedPathName[1] + "/", "");
  let searchQueryParam = window.location.search;
  let continueShoppingUrl = `${pathName}${searchQueryParam}`;
  localStorage.setItem("CONTINUE_SHOPPING_URL", continueShoppingUrl);
};

export const removeCountrySelectionDropdown = history => {
  try {
    const excludeCountrySelectionDropdown = EXCLUDE_COUNTRY_SELECTION_DROPDOWN;
    const pathName = get(history, "location.pathname", "");
    const splittedPathName = split(pathName, "/");
    if (excludeCountrySelectionDropdown.indexOf(splittedPathName[2]) > -1) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getStoreMap = settings => {
  const _storeMap = {};
  settings &&
    settings.stores &&
    settings.stores.forEach(_store => {
      let _storeURL = _store.name;
      _storeURL = _storeURL.toLowerCase();
      _storeURL = _storeURL.includes("toys")
        ? "toys"
        : _storeURL.replace(/ /g, "-");
      _storeMap[_store.mid] = `/${_storeURL}`;
    });
  return _storeMap;
};

export const isTouchScreen = () =>
  !isServer && window.matchMedia("(max-width: 812px)").matches;

export const isMiddleScreen = () =>
  !isServer && window.matchMedia("(max-width: 1200px)").matches;

export const generateArrayOfYearStrings = (yearsAmount = 12) => {
  yearsAmount = +yearsAmount;
  if (!Number.isInteger(yearsAmount) || yearsAmount <= 0) {
    console.error("Function generateArrayOfYearStrings got incorrect argument");
    return null;
  }
  const min = new Date().getFullYear();
  const max = min + yearsAmount;
  let years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString());
  }
  return years;
};

export const includesSubString = (string, subString) =>
  string.toUpperCase().includes(subString.toUpperCase());

export const checkUrlSearchMultiCategory = historySearch =>
  (
    historySearch
      .split("&")
      .find(
        item =>
          item.includes("categories-2") ||
          item.includes("hierarchicalCategories.lvl2")
      ) || ""
  ).split(",").length > 1;

export const getCountryNameFromUrl = (url = "") => {
  const regExp = /(en|ar)-([^/]+)/;
  if (!isString(url) || !url.match(regExp)) {
    return "";
  }
  const match = url.match(regExp);
  return match[2];
};

export const getSelectedFiltersWithItems = (productsObj, item) => {
  const selectedFacetsByItem = get(productsObj, ["selectedFacets", item]);
  const requestedFacets = get(productsObj, [item], []);
  const respondedFacets = get(productsObj, ["facets", item], {});

  return (selectedFacetsByItem || requestedFacets).filter(
    facet => respondedFacets[facet]
  );
};

export const filterAddressByState = (addressDetails, state) => {
  try {
    const allStateIds = map(state, "stateId");

    if (!size(state)) return addressDetails;

    return filter(addressDetails, address =>
      includes(allStateIds, parseInt(address.cityId))
    );
  } catch (error) {
    console.error("Failed to filter address details by state", error);
    return addressDetails;
  }
};

export const getWindowOrientation = () => {
  if (isServer) {
    console.error("window object is absent");
    return null;
  }
  const { innerWidth: width, innerHeight: height } = window;
  return width >= height ? "landscape" : "portrait";
};

export const renderPaymentModeMessage = (
  paymentMode,
  paymentExtraInfo,
  translation
) => {
  try {
    const replaceTabbyAndTamara = paymentMode
      .join(",")
      .replace(TABBY, paymentExtraInfo)
      .replace(TAMARA, paymentExtraInfo)
      .split(",")
      .sort();
    return replaceTabbyAndTamara.map(mode => translation([mode])).join(", ");
  } catch (error) {
    return "";
  }
};

export const getOptionalByValue = ({ value, keyName, callback = null }) =>
  value && { [keyName]: callback ? callback(value) : value };

export const getWishListGeneralDataStore = () =>
  selectWishListGeneralProducts(ContextStorage.store.getState());

export const isPresent = item => {
  const wishListBaseData = getWishListGeneralDataStore();
  return wishListBaseData?.some(
    el => el.productId === item.productId && (item.size || "" === el.size)
  );
};

export const getUniqueId = () => {
  const uniqueId = ContextStorage.cookies.get(UNIQUE_ID);
  if (uniqueId) return uniqueId;

  const newUniqueId = generateUUID();
  storeUniqueId(newUniqueId);

  return newUniqueId;
};

export const storeUniqueId = uniqueId => {
  ContextStorage.cookies.set(UNIQUE_ID, uniqueId, getDefaultCookieOptions());
};

export const setViewGrid = grid => {
  ContextStorage.cookies.set(VIEW_GRID, grid, getDefaultCookieOptions());
};

export const getViewGrid = () => {
  const viewGrid = ContextStorage.cookies.get(VIEW_GRID);
  if (viewGrid) return Number(viewGrid);

  setViewGrid(DEFAULT_VIEW_GRID_VALUE);
  return DEFAULT_VIEW_GRID_VALUE;
};

export const getVisitorId = () => {
  const visitorId = ContextStorage.cookies.get(VISITOR_ID);

  if (visitorId) return visitorId;

  const newVisitorId = generateUUID();
  ContextStorage.cookies.set(
    VISITOR_ID,
    newVisitorId,
    getDefaultCookieOptions()
  );

  return newVisitorId;
};

export const updateVisitorId = id => {
  const visitorId = id || generateUUID();
  ContextStorage.cookies.set(VISITOR_ID, visitorId, getDefaultCookieOptions());

  return visitorId;
};
//TODO: this is hotfix, remove this after implemented in stategy
import { BROWSER_STORAGE } from "../redux/constants";

export const getCountryId = countryShort => {
  const countryShortFromCookies = ContextStorage.cookies.get(
    BROWSER_STORAGE.USER_COUNTRY
  );

  if (!countryShortFromCookies && !countryShort) return null;

  const countryIds = {
    AE: 236,
    SA: 199,
    LB: 130,
    IN: 105,
    OM: 174,
    QA: 186,
    BH: 17,
    KW: 126,
    SG: 204,
    MY: 141
  };

  return countryIds[countryShort || countryShortFromCookies];
};

export const getAcceptablePhoneLengths = countrySetting => {
  if (!countrySetting) return [];
  return countrySetting.mobileNumberCriteria?.acceptableLength?.length
    ? countrySetting.mobileNumberCriteria.acceptableLength
    : [countrySetting.mobileLocalNumberLength];
};

export const getPreferredLanguage = () => {
  const preferredLanguage = ContextStorage.cookies.get(PREFERRED_LANGUAGE);
  if (!preferredLanguage) {
    setPreferredLanguage("en");
  }
  return preferredLanguage;
};

export const setPreferredLanguage = shortLanguage => {
  ContextStorage.cookies.set(
    PREFERRED_LANGUAGE,
    shortLanguage,
    getDefaultCookieOptions()
  );
};
