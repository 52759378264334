import {
  CREATE_USER,
  SESSION_TERMINATED,
  API_ERROR,
  RESET_API_ERROR,
  API_NOTIFICATION_MSG,
  RESET_NOTIFICATION_MSG,
  SET_REGISTRATION_DATA_STATUS,
  RESET_REGISTRATION_DATA_STATUS,
  SET_SIGNUP_LOGIN_TOKEN,
  RESET_SIGNUP_LOGIN_TOKEN,
  SET_SOCIAL_LOGIN_FLOW,
  RESET_SOCIAL_LOGIN_FLOW,
  SET_SOCIAL_PHONE_CHECK_TOKEN,
  RESET_SOCIAL_PHONE_CHECK_TOKEN,
  SET_ANALYTICS_DATA,
  SET_LAST_REGISTRATION_STATUS_CODE,
  SET_REGISTRATION_ERROR,
  SET_ADDRESS_OTP_ERROR,
  SET_ADDRESS_OTP_FLOW,
  SET_OTP_LOGIN_FLOW_BFL,
  SAVE_SOCIAL_ANALYTICS_DATA,
  SAVE_PHONE_FOR_ANALYTICS
} from "../constants";

type APIErrorResponse = {
  success: boolean;
  message: string;
};

type ForgotPassword = {
  APINotificationMsg: string;
};

type HomepageDataState = {
  storeId: string;
  countryId: string;
  sections: any[];
};

type AnalyticsData = Record<string, any>;

type AddressOtpFlow = {
  error: string;
};

type HomepageState = {
  isHomePage: boolean;
  isHomePageError: boolean;
  signUp: {
    nationality: number;
  };
  APIErrorResponse: APIErrorResponse;
  forgotPassword: ForgotPassword;
  homepageState: HomepageDataState;
  registrationDataStatus: any;
  signUpLoginToken: string;
  socialPhoneCheckToken: string;
  socialLoginFlow: boolean;
  otpLoginFlowBFL: boolean;
  analyticsData: AnalyticsData;
  socialAnalyticsData: any;
  lastRegistrationStatusCode: string;
  registrationError: string;
  addressOtpFlow: AddressOtpFlow;
};

const initialState: HomepageState = {
  isHomePage: false,
  isHomePageError: false,
  signUp: {
    nationality: 1
  },
  APIErrorResponse: {
    success: true,
    message: ""
  },
  forgotPassword: {
    APINotificationMsg: ""
  },
  homepageState: {
    storeId: "",
    countryId: "",
    sections: []
  },
  registrationDataStatus: {},
  signUpLoginToken: "",
  socialPhoneCheckToken: "",
  socialLoginFlow: true,
  otpLoginFlowBFL: false,
  analyticsData: {},
  socialAnalyticsData: {},
  lastRegistrationStatusCode: "",
  registrationError: "",
  addressOtpFlow: { error: "" }
};

const setAPIErrorResponse = (state: HomepageState, action) => ({
  ...state,
  APIErrorResponse: action.content
});
const resetAPIErrorResponse = (state: HomepageState) => ({
  ...state,
  APIErrorResponse: initialState.APIErrorResponse
});
const setAPINotificationMsg = (state: HomepageState, action) => ({
  ...state,
  forgotPassword: {
    APINotificationMsg: action.message
  }
});
const resetAPINotificationMsg = (state: HomepageState) => ({
  ...state,
  forgotPassword: {
    ...state.forgotPassword,
    APINotificationMsg: initialState.forgotPassword.APINotificationMsg
  }
});

const createUser = (state: HomepageState) => ({ ...state });

const setRegistrationDataStatus = (state: HomepageState, { payload }) => ({
  ...state,
  registrationDataStatus: payload
});

const resetRegistrationDataStatus = (state: HomepageState) => ({
  ...state,
  registrationDataStatus: state.registrationDataStatus
});

const setSignUpLoginToken = (state: HomepageState, { payload }) => ({
  ...state,
  signUpLoginToken: payload
});

const resetSignUpLoginToken = (state: HomepageState) => ({
  ...state,
  registrationDataStatus: initialState.signUpLoginToken
});

const setSocialLoginFlow = (state: HomepageState, { payload }) => ({
  ...state,
  socialLoginFlow: payload
});
const resetSocialLoginFlow = (state: HomepageState) => ({
  ...state,
  socialLoginFlow: initialState.socialLoginFlow
});

const setSocialPhoneCheckToken = (state: HomepageState, { payload }) => ({
  ...state,
  socialPhoneCheckToken: payload
});

const resetSocialPhoneCheckToken = (state: HomepageState) => ({
  ...state,
  socialPhoneCheckToken: initialState.socialPhoneCheckToken
});

export const setAnalyticsData = (state: HomepageState, { payload }) => ({
  ...state,
  analyticsData: payload
});

export const setLastRegistrationStatusCode = (
  state: HomepageState,
  { payload }
) => ({
  ...state,
  lastRegistrationStatusCode: payload
});

export const saveSocialAnalyticsData = (state: HomepageState, { payload }) => ({
  ...state,
  socialAnalyticsData: payload
});

export const savePhoneForAnalytics = (state: HomepageState, { payload }) => ({
  ...state,
  socialAnalyticsData: { ...state.socialAnalyticsData, phone: payload }
});

export const setRegistrationError = (state: HomepageState, { payload }) => ({
  ...state,
  registrationError: payload
});

const setAddressOtpError = (state: HomepageState, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, error: action.payload }
});

const setAddressOtpFlow = (state: HomepageState, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, ...action.payload }
});

const setOTPLoginFlowBFL = (state: HomepageState, action) => ({
  ...state,
  otpLoginFlowBFL: action.payload
});

const homepageReducer = (
  state: HomepageState = initialState,
  action
): HomepageState => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return state;
    case CREATE_USER:
      return createUser(state);
    case API_ERROR:
      return setAPIErrorResponse(state, action);
    case RESET_API_ERROR:
      return resetAPIErrorResponse(state);
    case API_NOTIFICATION_MSG:
      return setAPINotificationMsg(state, action);
    case RESET_NOTIFICATION_MSG:
      return resetAPINotificationMsg(state);
    case SET_REGISTRATION_DATA_STATUS:
      return setRegistrationDataStatus(state, action);
    case RESET_REGISTRATION_DATA_STATUS:
      return resetRegistrationDataStatus(state);
    case SET_SIGNUP_LOGIN_TOKEN:
      return setSignUpLoginToken(state, action);
    case RESET_SIGNUP_LOGIN_TOKEN:
      return resetSignUpLoginToken(state);
    case SET_SOCIAL_LOGIN_FLOW:
      return setSocialLoginFlow(state, action);
    case RESET_SOCIAL_LOGIN_FLOW:
      return resetSocialLoginFlow(state);
    case SET_SOCIAL_PHONE_CHECK_TOKEN:
      return setSocialPhoneCheckToken(state, action);
    case RESET_SOCIAL_PHONE_CHECK_TOKEN:
      return resetSocialPhoneCheckToken(state);
    case SET_ANALYTICS_DATA:
      return setAnalyticsData(state, action);
    case SAVE_SOCIAL_ANALYTICS_DATA:
      return saveSocialAnalyticsData(state, action);
    case SAVE_PHONE_FOR_ANALYTICS:
      return savePhoneForAnalytics(state, action);
    case SET_LAST_REGISTRATION_STATUS_CODE:
      return setLastRegistrationStatusCode(state, action);
    case SET_REGISTRATION_ERROR:
      return setRegistrationError(state, action);
    case SET_ADDRESS_OTP_ERROR:
      return setAddressOtpError(state, action);
    case SET_ADDRESS_OTP_FLOW:
      return setAddressOtpFlow(state, action);
    case SET_OTP_LOGIN_FLOW_BFL:
      return setOTPLoginFlowBFL(state, action);

    default:
      return state;
  }
};

export default homepageReducer;
