function baseUrl(defaultUrl) {
  let url;

  if (process.env.DOMAIN_NAME !== undefined) {
    url = `https://${process.env.DOMAIN_NAME}`;
  } else {
    url = defaultUrl;
  }

  return url;
}

export const config = {
  development: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl(process.env.REACT_APP_BASE_URL || ""),

    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
    listUserEmail: (process.env.REACT_APP_LIST_USER_EMAIL &&
      JSON.parse(process.env.REACT_APP_LIST_USER_EMAIL)) || [
      "sajin@bflgroup.ae"
    ],
    countryId: 236,
    paypal_env: process.env.REACT_APP_PAYPAL_ENV || "sandbox",
    tabby_public_key:
      process.env.REACT_APP_TABBY_PUBLIC_KEY ||
      "pk_test_d181d00e-2060-4399-a3c4-f4678c2ea46c",
    checkout_com_public_key:
      process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY ||
      "pk_sbox_gi2ojamqlkmfdz5fyt3xnlyrpyf"
  },
  staging: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl(
      process.env.REACT_APP_BASE_URL ||
        "https://bfl-web-client.qa3.tothenew.net"
    ),
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
    listUserEmail: (process.env.REACT_APP_LIST_USER_EMAIL &&
      JSON.parse(process.env.REACT_APP_LIST_USER_EMAIL)) || [
      "sajin@bflgroup.ae"
    ],
    countryId: 236,
    paypal_env: process.env.REACT_APP_PAYPAL_ENV || "sandbox",
    tabby_public_key:
      process.env.REACT_APP_TABBY_PUBLIC_KEY ||
      "pk_test_d181d00e-2060-4399-a3c4-f4678c2ea46c",
    checkout_com_public_key:
      process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY ||
      "pk_sbox_gi2ojamqlkmfdz5fyt3xnlyrpyf"
  },
  uat: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl(
      process.env.REACT_APP_BASE_URL ||
        "https://bfl-web-client.qa3.tothenew.net"
    ),
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
    listUserEmail: (process.env.REACT_APP_LIST_USER_EMAIL &&
      JSON.parse(process.env.REACT_APP_LIST_USER_EMAIL)) || [
      "sajin@bflgroup.ae"
    ],
    countryId: 236,
    paypal_env: process.env.REACT_APP_PAYPAL_ENV || "sandbox",
    tabby_public_key:
      process.env.REACT_APP_TABBY_PUBLIC_KEY ||
      "pk_test_d181d00e-2060-4399-a3c4-f4678c2ea46c",
    checkout_com_public_key:
      process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY ||
      "pk_sbox_gi2ojamqlkmfdz5fyt3xnlyrpyf"
  },
  qa: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl(
      process.env.REACT_APP_BASE_URL ||
        "https://bfl-web-client.qa3.tothenew.net"
    ),
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
    listUserEmail: (process.env.REACT_APP_LIST_USER_EMAIL &&
      JSON.parse(process.env.REACT_APP_LIST_USER_EMAIL)) || [
      "sajin@bflgroup.ae"
    ],
    countryId: 236,
    paypal_env: process.env.REACT_APP_PAYPAL_ENV || "sandbox",
    tabby_public_key:
      process.env.REACT_APP_TABBY_PUBLIC_KEY ||
      "pk_test_d181d00e-2060-4399-a3c4-f4678c2ea46c",
    checkout_com_public_key:
      process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY ||
      "pk_sbox_gi2ojamqlkmfdz5fyt3xnlyrpyf"
  },
  production: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl(
      process.env.REACT_APP_BASE_URL ||
        "https://bfl-web-client.qa3.tothenew.net"
    ),
    googleMap:
      process.env.GOOGLE_MAP_API_KEY ||
      "AIzaSyAtrprDyHGacssn71XBy5EaD0EgT9bHH9Y",
    listUserEmail: (process.env.REACT_APP_LIST_USER_EMAIL &&
      JSON.parse(process.env.REACT_APP_LIST_USER_EMAIL)) || [
      "sajin@bflgroup.ae"
    ],
    countryId: 236,
    paypal_env: process.env.REACT_APP_PAYPAL_ENV || "sandbox",
    tabby_public_key:
      process.env.REACT_APP_TABBY_PUBLIC_KEY ||
      "pk_test_d181d00e-2060-4399-a3c4-f4678c2ea46c",
    checkout_com_public_key:
      process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY ||
      "pk_sbox_gi2ojamqlkmfdz5fyt3xnlyrpyf"
  }
};

export const BEConfig = {
  customerApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.CUSTOMER_PORT || "",
    baseURL:
      process.env.REACT_APP_CUSTOMER_API_BASE_URL ||
      "qa-api.bfl.dev/customer-api",
    versionInfo: "/api/v6/",
    userHandle: "user",
    guestUserHandle: "user/guest-registration",
    loginUserHandle: "user/authenticate",
    resetEmailLinkHandle: "user/passwordEmail",
    tokenValidationHandle: "user/tokenStatus",
    resetPasswordHandle: "user/password",
    resetCurrentPasswordHandle: "user/resetPassword",
    addressHandle: "user/address",
    guestAddressHandle: "user/guest-address",
    userOrderDetails: "user/order/details",
    myAccountAddressHandle: "user/my-account/address",
    orderHandle: "user/orders",
    orderStatus: "user/orderStatus",
    myOrders: "account/orders",
    loyaltyDetails: "account/loyalty-details",
    loyaltyPoints: "account/loyalty-points",
    loyaltyExpiryDetails: "account/loyalty-expiry-details",
    loyaltyEarnedPoints: "account/loyalty-earned-points",
    registerLoyaltyProgramUser: "account/loyalty-registration",
    cancelOrder: "account/cancelOrder",
    wallet: "account/wallet",
    walletBalance: "account/wallet-balance",
    walletExpirationDetails: "account/wallet-expiry-details",
    trackOrder: "user/trackOrder",
    socialAccount: "user/socialAccount",
    subscribeEmailHandle: "user/subscribeEmail",

    getRegistrationDataStatus: "user/register/status",
    beginUserRegistration: "user/register",
    completeUserRegistration: "user/register/complete",
    deactivatePreviousAccounts: "user/register/generate-deactivate-data",

    socialAuthenticate: "user/auth/authenticate",
    checkMobileStatus: "user/auth/mobile-status",
    socialRegister: "user/auth/register",
    socialComplete: "user/auth/complete",
    socialDeactivatePreviousAccounts: "user/auth/generate-deactivate-data",
    completeUserDataUpdate: "user/update-complete",
    sendUpdatingOtp: "user/send-updating-otp",
    getDeactivateDataForUpdate: "user/generate-updating-deactivate-data",
    downloadLoyaltyInvoice: "account/download-invoice"
  },
  configApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.CONFIG_PORT || "",
    baseURL:
      process.env.REACT_APP_CONFIG_API_BASE_URL || "qa-api.bfl.dev/config-api",
    versionInfo: "/api/v3/"
  },
  productsApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.PRODUCTS_PORT || "",
    baseURL:
      process.env.REACT_APP_PRODUCTS_API_BASE_URL ||
      "qa-api.bfl.dev/products-api",
    versionInfo: "/api/v6/",
    productsPageHandle: "products",
    brandsPageHandle: "products/brands-list",
    loyaltyPointsHandle: "products/loyalty-points",
    trendingProducts: "products/trending",
    productSuggestions: "products/suggestions",
    productsById: "/get-product-count-by-sizeId"
  },
  wishListApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.WISHLIST_PORT || "",
    baseURL:
      process.env.REACT_APP_CART_API_BASE_URL || "qa-api.bfl.dev/cart-api",
    versionInfo: "/api/v6/",
    wishListPageHandle: "wishlist",
    moveToCart: "/move-to-cart",
    moveToWishlist: "/move-to-wishlist",
    cartPageHandle: "cart",
    moveSelectedItemsToWishList: "move-cart-items-to-wishlist",
    removeSelectedItemsFromCart: "delete-items",
    updateItemSelection: "item-selection"
  },
  subscribeNowApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    baseURL:
      process.env.REACT_APP_SUBSCRIBE_NOW_API_BASE_URL ||
      "marketing.brandsforless.ae",
    subscribeNowHandle: "pub/rf"
  },
  catalogApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.CATALOG_PORT || "",
    baseURL:
      process.env.REACT_APP_CATALOG_API_BASE_URL ||
      "qa-api.bfl.dev/catalog-api",
    versionInfo: "/api/v8/",
    homePageHandle: "config",
    getSection: "/section",
    getPageHandle: "get-page",
    megaMenuHandle: "mega-menu",
    sizeChartHandle: "size-chart",
    footerHandle: "footer",
    needHelpHandle: "need-help",
    pdpSizeChartHandle: "/product",
    generatedUrlHandle: "url/sys-generated-urls",
    bannerSections: "sections",
    configurations: "configurations",
    flashSaleInfo: "flash-sale/info",
    flashSaleProducts: "flash-sale",
    productsCarousel: "product/carousel-products"
  },
  commonApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.COMMON_PORT || "",
    baseURL:
      process.env.REACT_APP_COMMON_API_BASE_URL || "qa-api.bfl.dev/common-api",
    versionInfo: "/api/v5/",
    settingsHandle: "settings",
    statesHandle: "common/states",
    colors: "common/colors",
    getGeoIpHandle: "geo-ip",
    common: "common",
    shops: "shops",
    seoUnsafeBrandUrlsEndpoint: "common/brand-page",
    countryByIdSetting: "/country/",
    mobileDetails: "/mobile-details",
    shortCountryList: "/get-short-country-list",
    deliveryInfo: "/delivery-info",
    payLaterOptions: "/bnpl-list",
    collectionPointsEndpoint: "common/collection-points"
  },
  checkoutApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.CHECKOUT_PORT || "",
    baseURL:
      process.env.REACT_APP_CHECKOUT_API_BASE_URL ||
      "qa-api.bfl.dev/checkout-api",
    versionInfo: "/api/v12/",
    paypalConfirm: "/paypal/confirm",
    paypalToken: "/paypal/auth",
    checkoutHandle: "checkout",
    confirmOrderHandle: "checkout/confirm-order",
    checkoutOrderHandle: "checkout/checkoutOrder",
    checkoutItemHandle: "checkout/checkoutItem",
    applyVoucherHandle: "checkout/applyVoucher",
    getVouchersCode: "checkout/getVoucherCodes",
    otpHandle: "notification",
    verifyOtpHandle: "notification/verify",
    payCheckoutCom: "/checkout-com/pay",
    updateCheckoutById: "checkout/updateCheckoutDataById",
    tabbySession: "/tabby/session",
    tabbyVerifyPayment: "/tabby/verify",
    tamaraSession: "/tamara/session",
    tamaraAuthorize: "/tamara/authorise"
  },
  sendUsEmailApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    baseURL:
      process.env.REACT_APP_COMMON_API_BASE_URL || "qa-api.bfl.dev/common-api",
    versionInfo: "/api/v3/",
    sendUsEmailHandle: "common/questions"
  },
  zendeskApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    baseURL:
      process.env.REACT_APP_ZENDESKAPI_BASEURL || "bflsupport.zendesk.com",
    versionInfo: process.env.REACT_APP_ZENDESKAPI_API_VERSION || "/api/v2/",
    Authorization:
      process.env.REACT_APP_ZENDESKAPI_AUTHORIZATION ||
      "Bearer DSzWU6maapkQlLPGC89UezPAElwviRJRznTUluCn",
    zendeskApiUploadFileHandle: "uploads.json",
    zendeskApiUploadFormHandle: "requests.json",
    userformID: {
      Description: process.env.REACT_APP_ZENDESKAPI_DESCRIPTION || 26216682,
      Subject: process.env.REACT_APP_ZENDESKAPI_SUBJECT || 26216672,
      CustomerID: process.env.REACT_APP_ZENDESKAPI_CUSTOMERID || 27334341,
      OrderID: process.env.REACT_APP_ZENDESKAPI_ORDERID || 27369102,
      Mobile: process.env.REACT_APP_ZENDESKAPI_MOBILE || 27611872,
      TicketType: process.env.REACT_APP_ZENDESKAPI_TICKET_TYPE || 32202809,
      GuestEmail: process.env.REACT_APP_ZENDESKAPI_GUEST_EMAIL || 360000176677,
      Name: process.env.REACT_APP_ZENDESKAPI_NAME || 360000178258
    }
  },
  mapCollectionList:
    "https://api.fo-del.com/api/v0.2/client/collectionpoint/list",
  commonSettings: {
    huawei:
      process.env.REACT_APP_MOBILE_APP_DOWNLOAD_HUAWEI ||
      "https://bit.ly/3nJ4pYQ",
    ios:
      process.env.REACT_APP_MOBILE_APP_DOWNLOAD_IOS ||
      "https://apple.co/2kDqagp",
    android:
      process.env.REACT_APP_MOBILE_APP_DOWNLOAD_ANDROID ||
      "http://bit.ly/2lPZvNt"
  },
  googleApi: {
    userInfo: "https://www.googleapis.com/oauth2/v3/userinfo"
  }
};

export const ENV_CONFIG = config[process.env.REACT_APP_ENV || "development"];
